import './App.css';
import Body from './componenets/body/Body';

function App() {
  return (
    <div className='app'>
      {/* Main content */}
      <Body />
    </div>
  );
}

export default App;